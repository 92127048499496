var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"facility-view-bio-panel"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Facility Name: "+_vm._s(_vm.selectedFacilities.name)+" | Location: "+_vm._s(_vm.selectedFacilities.location)+" | Amount: "+_vm._s(_vm.selectedFacilities.quanity)+" | ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headerColumn,"items":_vm.selectedCorporates,"server-items-length":_vm.totalCorpsTable,"loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"32"}},[(item.vaccine_request.corporate.icon)?_c('v-img',{attrs:{"src":require(("@/assets/images/company/" + (item.vaccine_request.corporate.icon)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.vaccine_request.corporate.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{name: 'corporate-details', params: { id: item.uuid }}}},[_vm._v(" "+_vm._s(item.vaccine_request.corporate.name)+" ")]),_c('small',[_vm._v(_vm._s(item.vaccine_request.corporate.name))])],1)],1)]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.vaccine_request.corporate.location))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(item.vaccine_request.quantity))])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text--primary"},[_vm._v("$1,000.00")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("0.00%")]),_c('v-progress-linear',{staticClass:"project-progress mt-1",attrs:{"height":"6","rounded":"","color":_vm.resolveProgressVariant(80),"value":5}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"small btn-inline-link",staticStyle:{"opacity":"0.6px"},attrs:{"small":"","text":"","outlined":"","rounded":"","color":"info","to":{name: 'corporate-details', params: { id: item.uuid }}}},[_vm._v(" View ")]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }