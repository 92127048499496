<template>
  <v-row class="facility-view-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-row>
          <v-col cols="12">
            Facility Name: {{ selectedFacilities.name }} |
            Location: {{ selectedFacilities.location }} | 
            Amount: {{ selectedFacilities.quanity }} | 
          </v-col>
       </v-row>
    <v-data-table
      v-model="selectedRows"
      :headers="headerColumn"
      :items="selectedCorporates"
      :server-items-length="totalCorpsTable"
      :loading="loading"
      show-select
      >
      <!-- Name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <v-img
                v-if="item.vaccine_request.corporate.icon"
                :src="require(`@/assets/images/company/${item.vaccine_request.corporate.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.vaccine_request.corporate.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{name: 'corporate-details', params: { id: item.uuid }}"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{item.vaccine_request.corporate.name}}
              </router-link>
              <small>{{ item.vaccine_request.corporate.name }}</small>
            </div>
          </div>
        </template>
        <template #[`item.location`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.vaccine_request.corporate.location }}</span>
        </template>

        <!-- quantity -->
        <template #[`item.quantity`]="{item}">
          <div class="text--primary">{{item.vaccine_request.quantity}}</div>
        </template>

        <!-- paid -->
        <template #[`item.paid`]="{item}">
          <div class="text--primary">$1,000.00</div>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <span>0.00%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveProgressVariant(80)"
            :value="5"
          ></v-progress-linear>
        </template>

        <template #[`item.actions`]="{item}">
          <v-btn
            small
            text
            outlined
            rounded
            color="info"
            class="small btn-inline-link"
            :to="{name: 'corporate-details', params: { id: item.uuid }}"
            style="opacity: 0.6px"
          >
            View
          </v-btn>

          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item

              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
  import { avatarText, kFormatter } from '@core/utils/filter'
  import useMohList from '../useMohList'

  export default {
    name: 'RequestCorporates',
    props: {
      corporates: {
        type: [Array, Object],
        required: true,
      },
      facility: {
        type: [Array, Object],
        required: true,
      },
    },
    setup(props) {
      const MOH_APP_STORE_MODULE_NAME = 'mohRequest'
      const selectedRows = []
      const headerColumn = [
        { text: 'Corporate', value: 'name' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Amount', value: 'amount' },
        { text: 'STATUS', value: 'status' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
      const { loading } = useMohList()

      const selectedCorporates = props.corporates
      const selectedFacilities = props.facility

      return {
        headerColumn,
        selectedRows,
        selectedCorporates,
        selectedFacilities,
        avatarText,
        kFormatter,
        loading,
        totalCorpsTable: selectedCorporates.length,
        icons: {
          mdiCheck,
          mdiBriefcaseVariantOutline,
          mdiCheckboxBlankCircle,
        },
      }
    },
  }
</script>
