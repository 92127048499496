<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
      <moh-bio-pane :request="groups"/>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <v-card v-for="(group, index) in groups.groups" :key="index">
              <div class="ml-2">
                <request-corporates :corporates='group.group.corporates' :facility="group.group.facility"></request-corporates>
              </div>
            </v-card>
         
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { onUnmounted, ref, onMounted, computed } from '@vue/composition-api'
  import router from '@/router'
  import store from '@/store'

  // eslint-disable-next-line object-curly-newline
  import { mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'
  import mohStoreModule from './mohStoreModule'
  import RequestDetails from './components/RequestDetails.vue'
  import RequestCorporates from './components/RequestCorporates.vue'
  import RequestFacilities from './components/RequestFacilities.vue'
  import useMohList from './useMohList'
  import MohBioPane from './components/MohBioPane.vue'

  export default {
    components: {
      MohBioPane,
      RequestDetails,
      RequestCorporates,
      RequestFacilities,
    },
    setup() {
      const MOH_APP_STORE_MODULE_NAME = 'mohRequest'

      // Register module
      if (!store.hasModule(MOH_APP_STORE_MODULE_NAME)) store.registerModule(MOH_APP_STORE_MODULE_NAME, mohStoreModule)
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(MOH_APP_STORE_MODULE_NAME)) store.unregisterModule(MOH_APP_STORE_MODULE_NAME)
      })

      const userData = ref({})
      const userTab = ref(null)

      const { fetchMohRequest, groups } = useMohList()

      fetchMohRequest({ id: router.currentRoute.params.id })

      const tabs = [{ icon: mdiAccountOutline, title: 'Grouped Requests' }]

      return {
        groups,
        tabs,
        userTab,
        userData,
        fetchMohRequest,
      }
    },
  }
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
