<template>
  <v-row class="facility-view-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        {{ facility.name }}
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
  import { avatarText, kFormatter } from '@core/utils/filter'
  import store from '@/store'
  import { computed, onMounted } from 'vue-demi'
  import useMohList from '../useMohList'

  export default {
    name: 'RequestFacilities',
    props: {},
    setup(props) {
      const MOH_APP_STORE_MODULE_NAME = 'mohRequest'
      const selectedRows = []
      const headerColumn = [
        { text: 'facility', value: 'name' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Amount', value: 'amount' },
        { text: 'STATUS', value: 'status' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
      const { loading } = useMohList()

      const selectedFacility = [{ ...props.facility }]

      return {
        headerColumn,
        selectedRows,
        selectedFacility,
        avatarText,
        kFormatter,
        loading,
        totalCorpsTable: selectedFacility.length,
        icons: {
          mdiCheck,
          mdiBriefcaseVariantOutline,
          mdiCheckboxBlankCircle,
        },
      }
    },
  }
</script>
