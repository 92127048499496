<template>
  <v-row class="facility-view-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="request.icon ? '' : 'primary'"
            :class="request.icon ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="request.icon"
              :src="require(`@/assets/images/facilities/${request.icon}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(request.name) }}</span>
          </v-avatar>

          <span
            class="mb-2"
            style="font-size:0.9em"
          >{{ request.name }}</span>

         
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list v-if="request">

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Vaccine:</span>
              <span class="text--secondary">{{ request.vaccine.name }}</span>
            </v-list-item>

            <v-divider class="mt-3"/>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Quantity:</span>
              <span class="text--secondary text-capitalize">{{ request.quantity }}</span>
            </v-list-item>
            <v-divider class="mt-3"/>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Group(s): </span>
              <span class="text--secondary text-capitalize">{{ request.groups.length }}</span>
            </v-list-item>
            <v-divider class="mt-3"/>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary text-capitalize">{{ request.status.name }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
  import { avatarText, kFormatter } from '@core/utils/filter'

  export default {
    name: 'MohBioPane',
    props: {
      request: {
        type: [Object, Array],
        require: true,
      },
    },
    setup(props) {
      let request = props.request

      return {
        request,
        avatarText,
        kFormatter,
        icons: {
          mdiCheck,
          mdiBriefcaseVariantOutline,
          mdiCheckboxBlankCircle,
        },
      }
    },
  }
</script>
